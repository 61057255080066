.custom__css {
    font-size: 17px !important;
}
.auth-heading{
    text-align: center;
    margin-top:5rem;
    margin-bottom:5rem;
}
.polls_header{
    font-size: 1.7rem;
    font-weight: 600;
}
@media (max-width:1024px) {
    .custom__css {
        font-size: 11px !important;
    }
}
@media (max-width:425px) {
    .mbl_view{
        width: 100%;
    }
    .mbl_view_width{
        width: 60%;
    }
    h4{
        font-size: 1.1rem;
    }
    .mbl_view_font{
        font-size: 1.2rem;
    }
}